import { http } from '@/services/http-api'
import { LogMessagePage } from '@/components/model'
import { AxiosResponse } from 'axios'

const params = (page: number) => {
  const params = new URLSearchParams()
  params.append('page', String(--page))
  params.append('size', '25') // optional. default=20
  params.append('sort', 'timestamp,desc')
  return params
}

export const searchLogs = (page: number): Promise<AxiosResponse<LogMessagePage>> =>
  http.get('/api/logquery', {
    params: params(page)
  })

export const getLastseenUsers = () => http.get('/api/logquery/stats/lastseen')
