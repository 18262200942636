








































































































import { Component, Vue } from 'vue-property-decorator'
import { getAllCities, postCity } from '@/services/cities-client'
import { City, Org } from '@/components/model'
import { getAllOrgs } from '@/services/orgs-client'

@Component({
  components: {}
})
export default class Cities extends Vue {
  cities: City[] = []
  orgs: Org[] = []
  search = ''
  noOrg = false
  org = 'BEO_005'
  kt = 'BE'

  get filteredCities(): City[] {
    return this.cities
      .filter(c => this.filterNoOrg(c, this.noOrg))
      .filter(c => this.filterOrg(c, this.org))
      .filter(c => this.filterKt(c, this.kt))
      .filter(c => this.filterSearch(c, this.search))
      .sort((a, b) => a.id.plz.localeCompare(b.id.plz))
  }

  filterNoOrg(city: City, noOrg: boolean): boolean {
    if (!noOrg) {
      return true
    }
    return !city.orgKey || city.orgKey === 'null'
  }

  filterOrg(city: City, org: string): boolean {
    if (!org) {
      return true
    }
    return city.orgKey === org
  }

  filterKt(city: City, kt: string): boolean {
    if (!kt) {
      return true
    }
    return city.kantonAbk === kt
  }

  filterSearch(city: City, search: string): boolean {
    if (!search) {
      return true
    }
    return city.id.plz.includes(search) || city.id.name.toLowerCase().includes(search.toLowerCase())
  }

  get orgsDistinct() {
    return this.orgs.map(c => c.orgKey)
      .sort()
  }

  get ktsDistinct() {
    return this.cities.map(c => c.kantonAbk)
      .filter(c => !!c)
      .sort()
  }

  save(city: City) {
    postCity(city)
  }

  getOrgName(orgkey: string) {
    if (!orgkey) {
      return null
    }
    return this.orgs.find(o => o.orgKey === orgkey)?.name
  }

  created() {
    getAllCities().then((response) => {
      this.cities = response.data
    })
    getAllOrgs().then((response) => {
        this.orgs = response.data
      }
    )
  }
}
