import { LoginRequest, UserDetail } from '@/components/model'
import jwtDecode from 'jwt-decode'
import { JWT_KEY } from '@/components/constants'
import { postLogin } from '@/services/auth-client'

const jwt = localStorage.getItem(JWT_KEY)

const ROLE_ADMIN = 'ROLE_ADMIN'

export const auth = {
  namespaced: true,
  state: {
    jwt: jwt
  },
  actions: {
    login({ commit }, request: LoginRequest) {
      return postLogin(request)
        .then(response => {
          localStorage.setItem(JWT_KEY, response.data.jwt)
          commit('setJwt', response.data.jwt)
          return Promise.resolve()
        })
    },
    logout({ commit }) {
      localStorage.removeItem(JWT_KEY)
      commit('logout')
    }
  },
  mutations: {
    setJwt(state, jwt) {
      state.jwt = jwt
    },
    logout(state) {
      state.jwt = null
    }
  },
  getters: {
    loggedIn: state => {
      return !!state.jwt
    },
    username: state => {
      return !state.jwt ? '' : jwtDecode<UserDetail>(state.jwt).sub
    },
    roleAdmin: state => {
      return !state.jwt ? '' : jwtDecode<UserDetail>(state.jwt).scopes.includes(ROLE_ADMIN)
    }
  }
}
