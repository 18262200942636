






































import { Component, Vue } from 'vue-property-decorator'
import { getAllOrgs } from '@/services/orgs-client'
import { Org } from '@/components/model'

@Component({
  components: {}
})
export default class Orgs extends Vue {
  private orgs: Org[] = null

  created() {
    getAllOrgs().then((response) => {
      this.orgs = response.data
    })
  }
}
