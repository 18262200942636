














































import { Component, Vue } from 'vue-property-decorator'
import { getLicenseRequests } from '@/services/license-client'

@Component({
  components: {}
})
export default class NavBar extends Vue {
  numLicenseRequests = 0

  get roleAdmin() {
    return this.$store.getters['auth/roleAdmin']
  }

  created() {
    getLicenseRequests()
      .then((response) => {
        this.numLicenseRequests = response.data.length
      })
  }
}
