import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import { JWT_KEY } from '@/components/constants'
import Login from '@/views/Login.vue'
import Cities from '@/views/Cities.vue'
import Users from '@/views/Users.vue'
import Tenants from '@/views/Tenants.vue'
import LicenseRequests from '@/views/LicenseRequests.vue'
import Orgs from '@/views/Orgs.vue'
import Logs from '@/views/Logs.vue'
import LastSeen from '@/views/LastSeen.vue'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    redirect: '/logs'
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/cities',
    name: 'Cities',
    component: Cities
  },
  {
    path: '/orgs',
    name: 'Orgs',
    component: Orgs
  },
  {
    path: '/users',
    name: 'Users',
    component: Users
  },
  {
    path: '/tenants',
    name: 'Tenants',
    component: Tenants
  },
  {
    path: '/licensereq',
    name: 'LicenseRequests',
    component: LicenseRequests
  },
  {
    path: '/logs',
    name: 'Logs',
    component: Logs
  },
  {
    path: '/lastseen',
    name: 'LastSeen',
    component: LastSeen
  }
]

const router = new VueRouter({
  routes
})

router.beforeEach((to, from, next) => {
  const publicPages = ['/login']
  const authRequired = !publicPages.includes(to.path)
  const loggedIn = localStorage.getItem(JWT_KEY)

  if (authRequired && !loggedIn) {
    next('/login')
  } else if (to.path === '/login' && loggedIn) {
    next('/')
  } else {
    next()
  }
})

export default router
