


















































import { Component, Vue } from 'vue-property-decorator'
import { getAllUsers } from '@/services/users-client'
import { User } from '@/components/model'

@Component({
  components: {}
})
export default class Users extends Vue {
  private users: User[] = null

  created() {
    getAllUsers().then((response) => {
      this.users = response.data
    })
  }
}
