


























import Vue from 'vue'
import { http } from '@/services/http-api'
import NavBar from '@/components/NavBar.vue'

export default Vue.extend({
  name: 'App',

  components: { NavBar },

  methods: {
    doLogout: function () {
      this.$store.dispatch('auth/logout').then(() => {
        this.$router.push('/login')
      })
    }
  },

  computed: {
    loggedIn: function () {
      return this.$store.getters['auth/loggedIn']
    }
  },

  created: function () {
    const logout = () => this.$store.dispatch('auth/logout')
    const redirect = (path) => this.$router.push(path)
    const isLoggedIn = () => this.$store.getters['auth/loggedIn']
    const showUnavailable = (value: boolean) => {
      this.showSystemUnavailable = value
    }

    const responseInterceptor = (response) => {
      showUnavailable(false)
      return response
    }

    const errorInterceptor = (error) => {
      console.log(error)
      if (error.response && (error.response.status === 401 || error.response.status === 403)) {
        if (isLoggedIn()) {
          console.log('token expired. logging out user.')
          return logout().then(redirect('/login'))
        }
        return Promise.reject(error)
      }
      showUnavailable(true) // TODO: maybe exclude logging and other non-essential stuff.
    }

    http.interceptors.response.use(responseInterceptor, errorInterceptor)
  },

  data: () => ({
    showSystemUnavailable: false
  })
})
