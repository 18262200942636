






































import { Component, Vue } from 'vue-property-decorator'
import { getAllTenants } from '@/services/tenant-client'
import { Tenant } from '@/components/model'

@Component({
  components: {},
  filters: {}
})
export default class Tenants extends Vue {
  private tenants: Tenant[] = null

  created() {
    getAllTenants().then((response) => {
      this.tenants = response.data
    })
  }
}
