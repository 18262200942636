



























import { Component, Vue } from 'vue-property-decorator'
import { getLastseenUsers } from '@/services/logs-client'
import { LastseenUser } from '@/components/model'

@Component
export default class LastSeen extends Vue {
  private lastSeenUsers: LastseenUser[] = null

  created() {
    getLastseenUsers().then((response) => {
      this.lastSeenUsers = response.data
    })
  }
}
