






















































import { Component, Vue } from 'vue-property-decorator'

@Component({
  components: {}
})
export default class Login extends Vue {
  username = ''
  password = ''
  showErrorMessage = false
  showPassword = false

  created () {
    this.$store.dispatch('auth/logout')
  }

  doLogin () {
    this.$store.dispatch('auth/login', {
      username: this.username,
      password: this.password
    })
      .then(() => {
        this.$router.push('/')
      })
      .catch(() => {
        this.showErrorMessage = true
      })
  }
}
