








































import { Component, Vue } from 'vue-property-decorator'
import { LicenseRequest } from '@/components/model'
import { getLicenseRequests } from '@/services/license-client'

@Component({
  components: {}
})
export default class Cities extends Vue {
  licenseRequests: LicenseRequest[] = []

  created() {
    getLicenseRequests()
      .then((response) => {
        this.licenseRequests = response.data
      })
  }
}
