


































































import { Component, Vue } from 'vue-property-decorator'
import { searchLogs } from '@/services/logs-client'
import { LogMessagePage } from '@/components/model'

@Component({
  components: {}
})
export default class Logs extends Vue {
  private logsPage: LogMessagePage = null

  private page = 1

  loadPage() {
    searchLogs(this.page)
      .then((response) => {
        this.logsPage = response.data
        this.$vuetify.goTo(0, { duration: 0 })
      })
  }

  created() {
    this.loadPage()
  }

  get totalPages() {
    return this.logsPage?.totalPages || 0
  }
}
